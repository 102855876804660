// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timezone-block {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 8px;
  width: -webkit-fill-available;
}
`, "",{"version":3,"sources":["webpack://./../shared-components/lib/TimezoneBlock/style.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,oBAAoB;EACpB,sBAAsB;EACtB,QAAQ;EACR,6BAA6B;AAC/B","sourcesContent":[".timezone-block {\n  align-items: center;\n  display: inline-flex;\n  flex-direction: column;\n  gap: 8px;\n  width: -webkit-fill-available;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
