// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  align-items: center;
  background-color: var(--intelibly-branding-secondary-50);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px 20px;
  width: 100%;
}

.header .appointment-details {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./../shared-components/lib/Header/style.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,wDAAwD;EACxD,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB","sourcesContent":[".header {\n  align-items: center;\n  background-color: var(--intelibly-branding-secondary-50);\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  padding: 24px 20px;\n  width: 100%;\n}\n\n.header .appointment-details {\n  align-items: center;\n  align-self: stretch;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
