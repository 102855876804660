import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { parsePhoneNumber } from 'react-phone-number-input'

import { useReactiveVar, useSuspenseQuery } from '@apollo/client'
import { Typography } from '@mui/material'
import PropTypes from 'prop-types'

import { LOCATION } from './locationQuery'

import './style.css'

export const formattedLocationAddress = location => {
  return `${location.street}, ${location.city}`
}

export const formattedLocationPhone = location => {
  return parsePhoneNumber(location.phoneNumber, 'US').formatNational()
}

export const Header = ({ locationData, store }) => {
  const { locationId } = useReactiveVar(store)
  const { t } = useTranslation()
  const { data } = useSuspenseQuery(LOCATION, {
    variables: { id: locationId },
  })

  useEffect(() => {
    locationData(data.location)
  }, [data.location])

  function LocationInformation({ location }) {
    return (
      <>
        <Typography align="center" className="practice-name" variant="h5">
          {location.displayName}
        </Typography>
        <Typography
          align="center"
          className="text-wrapper"
          sx={{ fontWeight: 500 }}
          variant="body1"
        >
          {formattedLocationAddress(location)}
        </Typography>
        <Typography
          className="text-wrapper"
          sx={{ fontWeight: 500 }}
          variant="body1"
        >
          {formattedLocationPhone(location)}
        </Typography>
        <Typography
          className="appointment-type"
          sx={{
            color: 'var(--intelibly-branding-secondary-400)',
          }}
          variant="h5"
        >
          {t('New Patient Exam')}
        </Typography>
      </>
    )
  }

  return (
    <div className="header">
      <div className="appointment-details">
        {data && data.location && (
          <LocationInformation
            data-testid="location-info"
            location={data.location}
          />
        )}
      </div>
    </div>
  )
}

Header.propTypes = {
  locationId: PropTypes.string,
}
