import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import PropTypes from 'prop-types'
import { Subheader } from 'shared-components'

export const AppointmentTypeFilter = ({
  appointmentTypes,
  onAppointmentTypeSelected,
  selectedAppointmentType,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Subheader text={t('Select a Treatment')} />
      <Select
        aria-label="treatment type"
        id="type-select"
        data-testid="type-select"
        value={selectedAppointmentType?.name || ''}
        onChange={event => onAppointmentTypeSelected(event.target.value)}
        variant="outlined"
        color="secondary"
        fullWidth
        sx={{ borderRadius: '24px', alignItems: 'center' }}
      >
        {appointmentTypes.map(type => (
          <MenuItem key={type.id} value={type.name}>
            {t(type.name)}
          </MenuItem>
        ))}
      </Select>
    </>
  )
}

AppointmentTypeFilter.propTypes = {
  appointmentTypes: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, name: PropTypes.string })
  ),
  onAppointmentTypeSelected: PropTypes.func,
  selectedAppointmentType: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
}
