// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.primary {
  margin: 0;
  width: 100%;
  border: 0.063rem solid;
  border-radius: 5px;
  border-color: var(--intelibly-branding-primary-300);
}

.secondary {
  margin: 0;
  width: 100%;
  border: 0.063rem solid;
  border-radius: 5px;
  border-color: var(--intelibly-branding-secondary-300);
}
`, "",{"version":3,"sources":["webpack://./../shared-components/lib/Divider/style.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,WAAW;EACX,sBAAsB;EACtB,kBAAkB;EAClB,mDAAmD;AACrD;;AAEA;EACE,SAAS;EACT,WAAW;EACX,sBAAsB;EACtB,kBAAkB;EAClB,qDAAqD;AACvD","sourcesContent":[".primary {\n  margin: 0;\n  width: 100%;\n  border: 0.063rem solid;\n  border-radius: 5px;\n  border-color: var(--intelibly-branding-primary-300);\n}\n\n.secondary {\n  margin: 0;\n  width: 100%;\n  border: 0.063rem solid;\n  border-radius: 5px;\n  border-color: var(--intelibly-branding-secondary-300);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
