// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.request-appointment-button {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.request-appointment-button .helper-text {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.request-appointment-button .p {
  color: var(--intelibly-branding-secondary-400);
  font-family: 'Inter', Helvetica;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./../shared-components/lib/RequestAppointmentButton/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;EACR,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,WAAW;AACb;;AAEA;EACE,8CAA8C;EAC9C,+BAA+B;EAC/B,eAAe;EACf,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".request-appointment-button {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  width: 100%;\n}\n\n.request-appointment-button .helper-text {\n  background-color: #ffffff;\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  width: 100%;\n}\n\n.request-appointment-button .p {\n  color: var(--intelibly-branding-secondary-400);\n  font-family: 'Inter', Helvetica;\n  font-size: 14px;\n  font-weight: 500;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
