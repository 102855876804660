// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subheader {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.subheader .title-icon {
  margin-bottom: 8px;
}
`, "",{"version":3,"sources":["webpack://./../shared-components/lib/Subheader/style.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".subheader {\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n\n.subheader .title-icon {\n  margin-bottom: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
